<template>
    <div class="main-header">
        <head-badge />

        <el-image :src="logoUrl" style="width:30px;height: 30px;margin:auto 0 auto 30px"></el-image>
        <h2 style="margin-left: 10px">菜大王科技 - 司机端</h2>
        <el-button @click="collapseBtn" type="text" :icon="$store.getters.sidebar?'el-icon-s-unfold':'el-icon-s-fold'" class="menu-open-btn"></el-button>
        <el-popover trigger="hover" placement="bottom-end" class="user-popper" v-model="isOpen" v-if="$store.getters.userInfo">
            <el-card class="box-card" shadow="never">
                <div class="user-top item-bb">
                    <el-image :src="getHeadImg()"/>
                    <div class="user-name">
                        <p>{{ nickName }}</p>
                        <span>{{ $store.getters.userInfo.brief || '' }}</span>
                    </div>
                </div>
                <div class="item" @click="editPwd">修改密码</div>
                <div class="item" @click="seeDoc">操作文档</div>
                <div class="item item-login-out" @click="logout">退出登录</div>
            </el-card>
            <div slot="reference">
                <el-avatar size="medium" :src="getHeadImg()" shape="square"></el-avatar>
                <span class="nick_name">{{ nickName }}</span>&nbsp;
                <i :class="isOpen ?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
            </div>
        </el-popover>
        <edit-pwd ref="editPwd" />
    </div>
</template>

<script>
    import { removerToken } from '@/utils/session'
    import EditPwd from '../EditPwd/index'
    import HeadBadge from "./HeadBadge";

    export default {
        name: "Header",
        components: {EditPwd, HeadBadge},
        data() {
            return {
                nickName: undefined,
                squareUrl: require('@/assets/logo.png'),
                logoUrl: require("@/assets/logo.png"),
                isOpen: false
            }
        },
        created() {
            this.getNickName()
        },
        methods: {
            getNickName:function(){
              this.nickName = this.$store.getters.nickName;
            },
            collapseBtn: function () {
                this.$store.dispatch("app/collapse");
            },
            getHeadImg(){
                return this.$store.getters.userInfo.headImg || this.squareUrl
            },
            editPwd(){
                this.$refs['editPwd'].open = true;
            },
            seeDoc(){
                window.open("http://www.dgscdw.com/food.shop.manager/dist/index.html#/driver/home.html")
            },
            logout(){
                this.$request({
                    url: 'auth/logout',
                    method: 'POST'
                }).then(({code}) => {
                    if(code === 0){
                        removerToken();
                        this.$store.dispatch("user/logout")
                        this.$store.commit("app/LOGOUT")
                        window.location.href = "/"
                    }
                }).catch(()=>{})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menu-open-btn{font-size: 28px;color: #000;margin-left: 40px;}
    .main-header {
        display: flex;
        .user-popper {
            cursor: pointer;
            right: 40px;
            position: absolute;

            .el-avatar {margin: auto;bottom: 0;top: 0;position: absolute;margin-left: -46px;}
        }
    }
    .text:hover{color: red;}

    .box-card {
        width: 300px;
        border: none;
        .item-login-out{border-top: 1px solid #f1f1f1;padding-top: 10px;margin-top: 10px}
        .item-bb{border-bottom: 1px solid #f1f1f1;}
        .item:hover{color: #000;}
        .item{cursor: pointer;color: #777;font-size: 16px;line-height: 38px;}
        .el-image {width:60px;border-radius: 50%;border: 1px solid #dddddd;}
        .user-name {margin-left: 10px;margin-top: 10px;font-size: 16px;}
        span{font-size: 14px;color: #999999;}
        .user-top{display: flex; padding-bottom: 16px;margin-bottom: 10px}
    }
</style>
