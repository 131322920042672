import request from '@/utils/request'


// 获取用户列表
export function listUser(data) {
    return request({
        url: '/system/user/list',
        method: 'GET',
        params: data
    })
}

//保存用户
export function saveManager(data) {
    return request({
        url: '/system/user/put',
        method: 'POST',
        data: data
    })
}

//修改用户
export function updateManager(data) {
    return request({
        url: '/system/user/put',
        method: 'put',
        data: data
    })
}

//删除用户
export function removeManager(guid) {
    return request({
        url: '/system/user/remove/'+guid,
        method: 'DELETE'
    })
}

//重置密码
export function resetPwd(guid) {
    return request({
        url: '/system/user/resetPwd/'+guid,
        method: 'POST'
    })
}

//获取用户
export function getManager(guid) {
    return request({
        url: '/system/user/get/'+guid,
        method: 'GET'
    })
}

//修改账户状态
export function updateStatus(guid, status) {
    return request({
        url: '/system/user/update/'+guid+"/"+status,
        method: 'POST'
    })
}

//批量删除
export function removeManagers(ids) {
    return request({
        url: '/system/user/removes',
        method: 'DELETE',
        data: ids
    })
}

// 修改密码
export function editPwd(data) {
    return request({
        url: '/system/user/editPwd',
        method: 'POST',
        data: data
    })
}
