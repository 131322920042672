<template>
    <div class="head-badge">
        <el-badge :is-dot="noticeDot()" class="item">
            <el-tooltip effect="dark" content="消息通知" placement="bottom">
                <div  @click="routePush('/delivery/traffic')">
                    <svg-icon icon-class="message-solid"/>
                </div>
            </el-tooltip>
        </el-badge>
    </div>
</template>

<script>
    export default {
        name: "HeadBadge",
        data(){
            return {

            }
        },
        methods: {
            noticeDot(){
                return this.$store.getters.noticeDot;
            },
            routePush(url) {
                if(this.$route.path !== url) {
                    if(url === '/delivery/traffic'){
                        this.$store.dispatch('socket/setNoticeDot', false) ;
                    }
                    this.$router.push(url);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .head-badge{
        position: absolute;
        right: 230px;
        .item{line-height: 0;margin: 0 10px;}
        .svg-icon {font-size: 24px;}
    }
</style>
