const router = {
    name: '系统管理',
    path: 'system',
    component: () => import('@/components/RouterView/'),
    // redirect: '/system/menu',
    children: [{
        name: '菜单管理',
        path: 'menu',
        component: () => import("@/views/system/menu/"),
        meta: {role: 'system:menu:list'}
    }, {
        name: '用户管理',
        path: 'user',
        component: () => import("@/views/system/user/"),
        meta: {role: 'system:user:list'}
    }, {
        name: '角色管理',
        path: 'role',
        component: () => import('@/views/system/role/'),
        meta: {role: 'system:role:list'}
    }, {
        name: '司机管理',
        path: 'driver',
        component: () => import("@/views/system/driver/"),
        meta: {role: 'system:driver:list'}
    },{
        name: '日志管理',
        path: 'log',
        component: () => import('@/views/system/log/'),
        meta: {role: 'system:log:list'}
    }, {
        name: '在线用户',
        path: 'monitor',
        component: () => import('@/views/system/monitor/'),
        meta: {role: 'system:monitor:list'}
    },{
        name: '系统配置',
        path: 'config',
        component: () => import('@/views/system/config/'),
        meta: {role: 'system:config:list'}
    }]
}

export default router

