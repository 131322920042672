<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
	export default {
		name: 'App',
		created() {
			// 监听页面关闭
			window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
			// console.log(process.env);
		},
		methods: {
			beforeunloadFn(e) {
				if(this.$store.getters.socket) {
					this.$store.getters.socket.close();
				}
			}
		}
	}
</script>
