import router from './router'
import {getToken, removerToken} from './utils/session'
import { Message } from 'element-ui'
import store from './store'
import NProgress from 'nprogress' // 进度条
import {haveRole} from './utils/app'
import 'nprogress/nprogress.css' //样式必须引入

const whiteList = ['/login'];
NProgress.configure({ showSpinner: false })

router.beforeEach(async(to, from, next) => {

    NProgress.start();
    document.title = "菜大王后台管理-"+ to.name;

    // 确定用户是否登录

    const hasToken = getToken()
    if(hasToken){
        if(to.path === '/login'){
            next({ path: '/' })
            NProgress.done()
        }else {
            try {
                if(store.getters.nickName){
                    if(to.meta && to.meta.role){
                        if(haveRole(to.meta.role) === false){
                            console.log("权限不足")
                            next("/404")
                        }
                    }
                    next();
                    NProgress.done()
                } else {
                    /** 获取用户信息 */
                    const data = await store.dispatch('user/getInfo')

                    /** 获取左侧菜单 */
                    const menus = await store.dispatch("app/setMenus")
                    //console.log(menus);

                    const warehouse = await store.dispatch('user/getUserWarehouse')
                    console.log(warehouse)

                    if(to.meta && to.meta.role){
                        if(haveRole(to.meta.role) === false){
                            console.log("权限不足")
                            next("/404")
                        }
                    }
                    next({...to, replace: true});
                }
            }catch (error) {
                removerToken();
                Message.error(error || 'Has Error')
                next(`/login?redirect=${to.path}`)
                NProgress.done()
            }

        }
    }else{
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免费登录白名单中，直接进入
            next()
        } else {
            // 其他没有访问权限的页面将重定向到登录页面.
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    }

})

router.afterEach(() => {
    NProgress.done()
})
