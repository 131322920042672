import axios from 'axios'
import {Message, Notification} from 'element-ui'
import {getToken, removerToken} from './session'
import qs from 'qs'
import router from '@/router'

// 返回这些状态码则需要重新登录
const againLoginCode = [ '1400', '1401', '1402', '1403'];

// create an axios instance
const service = axios.create({
    baseURL: "/api/", // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 120000, // request timeout 设置超时时间2分钟
    paramsSerializer: (params) => qs.stringify(params, {indices: false})
})

// service.interceptors.tran

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        config.headers['X-Header-Token'] = getToken()
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'

        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */
    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        // 如果返回的消息头里携带文件名则下载文件
        const fileName = response.headers['filename'];
        if(fileName !== undefined){
            // 文件下载
            downLoad(response, decodeURI(fileName));

            return response;
        }

        const res = response.data

        // 如果返回的状态码不是(0)则提示异常
        if (res.code !== 0) {
            Notification({
                title: '提示',
                message: res.message || 'Error',
                type: 'error'
            })

            if (againLoginCode.indexOf(res.code.toString()) > -1) {
                // token 过期或不正确 重新登录
                removerToken()
                router.push("/login")
            }

            if (res.code === 1501) {
                router.push("/401")
            }
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        if(error.message.includes('timeout')){   // 判断请求异常信息中是否含有超时timeout字符串
            Notification({
                title: '提示',
                message: "网络连接超时, 请检查网络",
                type: 'error'
            })
        }else {
            Notification({
                title: '提示',
                message: error.message,
                type: 'error'
            })
        }
        return Promise.reject(error)
    }
)

/** 下载文件 */
function downLoad(res, fileName) {
    const blob = new Blob([res.data], {type: res.headers['content-type']}); //指定格式为vnd.ms-excel
    const downloadElement = document.createElement('a');
    const href = window.URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = fileName; //下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对象
}


export default service
