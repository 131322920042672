const router = {
    name: '配送管理',
    path: 'delivery',
    component: () => import('@/components/RouterView/'),
    // redirect: '/system/menu',
    children: [{
        name: '路况记录',
        path: 'traffic',
        component: () => import("@/views/delivery/traffic/"),
        meta: {role: 'delivery:traffic:list'}
    },{
        name: '监控管理',
        path: 'monitor',
        component: () => import("@/views/delivery/monitor/index-gaode.vue"),
        meta: {role: 'delivery:monitor'}
    },{
        name: '轨迹查看',
        path: 'trail',
        component: () => import("@/views/delivery/trail/index-gaode.vue"),
        meta: {role: 'delivery:trail'}
    },{
        name: '配送记录',
        path: 'record',
        component: () => import("@/views/delivery/record/"),
        meta: {role: 'delivery:record:list'}
    },{
        name: '司机运费',
        path: 'freight',
        component: () => import("@/views/delivery/freight/"),
        meta: {role: 'delivery:freight:list'}
    },
    {
        name: '打卡记录',
        path: 'clock',
        component: () => import("@/views/delivery/clock/"),
        meta: {role: 'driver:clock:list'}
    },
    {
        name: '司机当月开票商户运费',
        path: 'invoiceFreight',
        component: () => import("@/views/delivery/invoiceFreight/"),
        meta: {role: 'delivery:Invoice:list'}

    },
    {
        name: '打卡图片',
        path: 'clockImg',
        component: () => import("@/views/delivery/clockImg/"),
        meta: {role: 'driver:clock:list'}
    },
    {
        name: '补单记录',
        path: 'supplement',
        component: () => import("@/views/delivery/supplement/"),
        meta: {role: 'delivery:supplement:list'}
    },
    {
        name: '司机容器',
        path: 'driverContainer',
        component: () => import("@/views/delivery/driverContainer/"),
        meta: {role: 'container:driver:list'}
    },
    {
        name: '客户容器',
        path: 'companyContainer',
        component: () => import("@/views/delivery/companyContainer/"),
        meta: {role: 'container:company:list'}
    },
    {
        name: '接单状态',
        path: 'driverOrderStatus',
        component: () => import("@/views/delivery/driverOrderStatus/"),
        meta: {role: 'delivery:order_status:list'}
    },
    {
        name: '商户对应司机月运费',
        path: 'merchantMonth',
        component: () => import("@/views/delivery/merchantMonth/"),
        meta: {role: 'delivery:merchant_onth:list'}
    },
    {
        name: '仓库运费',
        path: 'warFreight',
        component: () => import("@/views/delivery/warFreight/"),
        meta: {role: 'delivery:war_freight:list'}
    },
    {
        name: '平台运费',
        path: 'platformFreight',
        component: () => import("@/views/delivery/platformFreight/"),
        meta: {role: 'delivery:platform_freight:list'}
    }]
}

export default router

