import request from '@/utils/request'

const state = {
    sidebar: false,
    size: 'small',
    menus: [],
    uploadUrl: process.env.VUE_APP_UPLOAD_IMG_PATH,
    orgImgUrl: process.env.VUE_APP_GET_IMG_PATH
}

const actions = {
    collapse: ({commit}) => {
        commit('COLLAPSE')
    },
    setSize: ({commit}, size) => {
        commit('SET_SIZE', size)
    },
    setMenus: ({commit}) => {
        return new Promise((resolve, reject) => {
            request({
                url: '/system/menu/getMyMenu',
                method: 'GET'
            }).then(({code, data}) => {
                if (code === 0) {
                    commit('SET_MENUS', data);
                    resolve(data);
                } else {
                    reject();
                }
            })
        })

    }
}

const mutations = {
    COLLAPSE: (state) => {
        state.sidebar = !state.sidebar
    },
    SET_SIZE: (state, size) => {
        state.indexPath = size
    },
    SET_MENUS: (state, menus) => {
        menus.unshift({"menuName": "首页","guid":"default", "path": "/default", "icon": "dashboard", "index": "1-1"})
        state.menus = menus
    },
    LOGOUT: (state) => {
        state.menus = [{"menuName": "首页","guid":"default", "path": "/default", "icon": "dashboard", "index": "1-1"}]
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
