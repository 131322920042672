function isAdmin(state) {
    if(state.user.userInfo){
        if(state.user.userInfo.isAdmin === 'Y'){
            return true;
        }
    }
    return false
}


const getters = {
    nickName : state => state.user.nickName,
    userInfo: state => state.user.userInfo,
    roles: state => state.user.roles,
    sidebar: state => state.app.sidebar,
    isAdmin: state => isAdmin(state),
    size : state => state.app.size,
    menus: state => state.app.menus,
    uploadUrl: state => state.app.uploadUrl,
    orgImgUrl: state => state.app.orgImgUrl,
    warehouse: state => state.user.warehouse,
    socket: state => state.socket.socket,
    noticeDot: state => state.socket.noticeDot
}

export default getters
