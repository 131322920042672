import { MessageBox, Notification} from 'element-ui'
import { removerToken } from '@/utils/session'

function onmessage(data, state, dispatch) {
    try {
        const jsonMsg = JSON.parse(data);
        if (jsonMsg.code === '9999') {
            MessageBox({
                title: '提示',
                showClose: false,
                confirmButtonText: '确定',
                closeOnClickModal: false,
                closeOnPressEscape: false,
                message: '你已被管理员强制登出, 请重新登录',
                callback: (d) => {
                    if(d === 'confirm'){
                        removerToken();
                        window.location.replace("/login")
                        dispatch("clear")
                    }
                }
            })
        }else{
            // 司机路况上报
            if(jsonMsg.code === '10001'){
                trafficMsg(jsonMsg.data);
                state.noticeDot = true;
            }
        }
    }catch (e) {
        console.log(e)
    }
}

/** 路况上报提示 */
function trafficMsg(params) {
    const message =`<p style="line-height: 1.8;"><label>上报概述</label>：${params.summary}</p>` +
                `<p style="line-height: 1.8;"><label>车&nbsp; 牌 &nbsp;号</label>：${params.vehicle}</p>` +
                `<p style="line-height: 1.8;"><label>司机电话</label>：${params.driverTel}</p>` +
                (params.remark ? `<p><label>备注信息</label>：${params.remark}</p>` : ``);
    Notification({
        title: `司机${params.driverName}上报路况异常`,
        dangerouslyUseHTMLString: true,
        message: message,
        duration: 15000,
        position: "bottom-right"
    })
}



export default {
    namespaced: true,
    state: {
        noticeDot: false,             //通知消息红点是否显示
        socket: undefined,
        url: '',
        token: undefined,
        lockReconnect: false,         //是否真正建立连接
        timeout: 25 * 1000,           //25秒一次心跳
        timeoutObj: undefined,        //心跳心跳倒计时
        serverTimeoutObj: undefined,  //心跳倒计时
        timeoutNum: undefined,        //断开 重连倒计时
        count: 0,                     //第几次重连 超过10次就不重连了
    },
    actions: {
        /** socket 初始连接 */
        SOCKET_INIT: ({dispatch,state}, data) => {
            state.url = data[0];
            state.token = data[1];

            const socket = new WebSocket(data[0] + "?type=2", data[1]);
            state.websock = socket
            socket.onopen = () => {
                console.log("Socket连接成功...");
                //开启心跳检测
                dispatch('start');
                state.count = 0;
            }
            socket.onmessage = (callBack) => {
                // console.log(callBack.data)
                //重置心跳
                dispatch('reset')
                // console.log("收到服务器数据：" + callBack.data)
                // eslint-disable-next-line no-empty
                if (callBack.data === 'heartCheck') {
                    // 心跳数据包
                }else{
                    onmessage(callBack.data, state,dispatch);
                }
            }
            socket.onerror = () => {
                // 错误
                // console.log(e)
                dispatch('reconnect')
            }
            socket.onclose = () => {

            }
        },
        /** 发送消息 */
        SOCKET_SEND({state}, message) {
            console.log(message)
        },
        /** 重新连接 */
        reconnect({dispatch,state}) {
            console.log(`socket重新连接第${state.count}次`)
            if (state.lockReconnect) {
                return;
            }
            if(state.count >= 10){
                // 重连10次没成功就不连接了
                dispatch("clear");
                return;
            }
            state.count += 1;
            state.lockReconnect = true;
            //没连接上会一直重连，设置延迟避免请求过多
            state.timeoutnum && clearTimeout(state.timeoutnum);
            state.timeoutnum = setTimeout(() => {
                //新连接
                dispatch('SOCKET_INIT', [state.url, state.token])
                state.lockReconnect = false;
            }, 5000);
        },
        /** 重置心跳 */
        reset({dispatch, state}) {
            //清除时间
            clearTimeout(state.timeoutObj);
            clearTimeout(state.serverTimeoutObj);
            //心跳
            dispatch('start')
        },
        /** 清除定时器 */
        clear({state}){
            clearTimeout(state.timeoutObj);
            clearTimeout(state.serverTimeoutObj);
        },
        /** 开启心跳 */
        start({dispatch,state}) {
            // console.log("开启心跳")
            state.timeoutObj && clearTimeout(state.timeoutObj);
            state.serverTimeoutObj && clearTimeout(state.serverTimeoutObj);
            state.timeoutObj = setTimeout(() => {
                //这里发送一个心跳，后端收到后，返回一个心跳消息，
                // console.log(state.websock)
                if (state.websock.readyState === 1) { //如果连接正常
                    state.websock.send("heartCheck");
                } else { //否则重连
                    dispatch('reconnect')
                }
                state.serverTimeoutObj = setTimeout(() => {
                    //超时关闭
                    state.websock.close();
                }, state.timeout);
            }, state.timeout)
        },
        setNoticeDot({commit}, dot){
            commit('setNoticeDot', dot)
        }
    },
    mutations: {
        setNoticeDot(state, dot){
            state.noticeDot = dot;
        }

    }
}
