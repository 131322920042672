<template>
    <div>
        <el-submenu v-if="isSubmenu()" :index="item.guid">
            <template slot="title">
                <item v-if="item.icon" :icon="item.icon" :title="item.menuName"></item>
            </template>
            <sidebar-item v-for="(route, index) in item.children" :item="route" :key="index"/>
        </el-submenu>
        <template v-else>
            <el-menu-item :index="item.path || item.guid">
                <item v-if="item.icon" :icon="item.icon" :title="item.menuName"></item>
            </el-menu-item>
        </template>

    </div>
</template>

<script>
    import Item from './Item'
    // import AppLink from './Link'
    // import FixiOSBug from './FixiOSBug'

    export default {
        name: 'SidebarItem',
        components: {Item},
        // mixins: [FixiOSBug],
        props: {
            // route object
            item: {
                type: Object,
                required: true
            }
        },
        data() {
            return{}
        },
        created() {

        },
        methods: {
            isSubmenu(){
                if(this.item){
                    return this.item.children && this.item.children.length>0;
                }
                return false;
            }
        }
    }
</script>
