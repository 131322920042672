<template>
    <el-aside class="sidebar-menus"
              :class="isCollapse()? 'is-open':''"
              :width="isCollapse()?'64px':'260px'">

        <el-menu text-color="#fff"
                 active-text-color="#409EFF"
                 class="sidebar_menu"
                 :collapse="isCollapse()"
                 :router="true"
                 :default-active="$route.path"
                 unique-opened>
            <sidebar-item
                    v-for="(route, index) in $store.getters.menus"
                    :key="index"
                    :item="route"
            />

            <!--<MenuTree :menuData="menuList" />-->
        </el-menu>
    </el-aside>
</template>

<script>
    //import MenuTree from "./MenuTree";
    import SidebarItem from './SidebarItem'

    export default {
        name: "Sidebar",
        components: {"SidebarItem": SidebarItem},
        data() {
            return {
                asideWidth: 60
            }
        },
        methods: {
            isCollapse() {
                return this.$store.getters.sidebar;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .sidebar-menus {
        background-color: #545c64;
        -webkit-transition: width 300msnpm
    }
</style>
