import Vue from "vue"
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().map((val) => {
	const value = modulesFiles(val)
	return value.default;
})

const router = new VueRouter({
	mode: "history",
	routes: [{
		path: "/",
		redirect: '/default',
		component: Layout,
		children: modules
	},{
		path: '/404',
		name: '404',
		component : () => import('@/views/error-views/404/')
	},{
		path: '/401',
		name: '401',
		component: () => import('@/views/error-views/401/')
	},{
		path: '/login',
		name: '登录',
		component: () => import('@/views/login/')
	}]
})

export default router
