<template>
    <el-container>
        <el-header>
            <Header/>
        </el-header>

        <el-container>
            <Sidebar/>
            <el-main>
                <router-view/>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import Sidebar from "./Sidebar/";
    import Header from "./Header/";
    import {getToken, removerToken} from '@/utils/session'

    export default {
        name: "Layout",
        data() {
            return {}
        },
        components: {
            'Sidebar': Sidebar,
            'Header': Header
        },
        created() {
            const token = getToken();
            if(!this.$store.getters.socket && token) {
                this.$store.dispatch("socket/SOCKET_INIT", [process.env.VUE_APP_SOCKET, this.$store.getters.userInfo.token])
            }
        },
        methods: {}
    }
</script>

<style scoped>

</style>
