import Vue from 'vue'
import App from './App.vue'
import request from './utils/request'

import './assets/icons' // icon
// 加载element组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import './style/index.scss'
import './permission'

// 加载路由
import router from './router'
import store from "./store";

// 注册自定义全局函数
import appJs from './utils/app'

Vue.use(appJs);
Vue.prototype.$request = request;


/** ------------------地图----------------- */
// 百度地图
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {ak: 'o8sNfGQWAxcy3HLCS9c5gWZ7vLbGnnXh'})

// 高德地图
import VueAMap from 'vue-amap';

Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
    // 高德的key
    key: '48243fed2385132f381d6d9054dadb9b',
    // 插件集合
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
    // 高德 sdk 版本，默认为 1.4.4
    v: '1.4.4'
});
/** ------------------地图End-------------- */

/** ------------------打印----------------- */
import Print from 'vue-print-nb'

Vue.use(Print);
/** ------------------打印End-------------- */


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
