<template>
    <el-dialog :visible.sync="open" :close-on-click-modal="false" width="450px" append-to-body @close="cancel">
        <el-row :gutter="15">
            <el-form :model="form" :rules="rules" ref="form" label-width="90px" :size="$store.getters.size">
                <el-col :span="24">
                    <el-form-item label="原密码" prop="password">
                        <el-input type="password" v-model="form.password" placeholder="请输入原始密码"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="新密码" prop="newPassword">
                        <el-input type="password" v-model="form.newPassword" placeholder="请输入新密码"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="确认密码" prop="onPassword">
                        <el-input type="password" v-model="form.onPassword" placeholder="请再次输入新密码"></el-input>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row>
        <div slot="footer" class="dialog-footer">
            <el-button :size="$store.getters.size" type="primary" @click="submitForm" :loading="loading">确 定</el-button>
            <el-button :size="$store.getters.size" @click="cancel">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {editPwd} from '@/api/system/user'

    export default {
        name: "EditPwd",
        data(){
            const newPasswordRoles= (rule, val, callback) => {
                if(val.length < 6){
                    callback(new Error("密码长度不能低于6位"))
                }
                callback();
            }
            const passwordRules = (rule, val, callback) => {
                if(val !== this.form.newPassword){
                    callback(new Error("两次密码不一致"))
                }
                callback();
            }
            return {
                title: '修改密码',
                open: false,
                form: {},
                loading: false,
                rules: {
                    password: [{ required: true, message: "原始密码不能为空", trigger: "blur" }],
                    newPassword: [{ required: true, message: "新密码不能为空", trigger: "blur" },
                        {validator: newPasswordRoles, trigger: 'blur'}],
                    onPassword: [{ required: true, message: "确认密码不能为空", trigger: "blur" },
                        {validator: passwordRules, trigger: "blur" }]
                }
            }
        },
        methods: {
            submitForm(){
                this.$refs['form'].validate(valid =>{
                    if(valid){
                        this.loading = true;
                        editPwd(this.form).then(({code})=> {
                            this.loading = false;
                            if(code === 0){
                                this.$message.success("密码修改成功,下次登录生效")
                                this.cancel();
                            }
                        }).catch(()=>{this.loading = false;})
                    }
                })
            },
            cancel(){
                this.form = {
                    password: undefined,
                    newPassword: undefined,
                    onPassword: undefined
                }
                this.open = false
            }
        }
    }
</script>

<style scoped>

</style>
