<script>
    export default {
        name: 'MenuItem',
        functional: true,
        props: {
            icon: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: ''
            }
        },
        render(h, context) {
            const {icon, title} = context.props
            const vnodes = []

            if (icon) {
                if(icon.indexOf("el") !== -1){
                    vnodes.push(<i class={icon} style='color:#FFFFFF;'/>)
                }else{
                    vnodes.push(<svg-icon icon-class={icon} style='margin-left: 5px; margin-right: 10px;'/>)
                }
            }

            if (title) {
                vnodes.push(<span slot='title'>{ ( title) }</span>)
            }
            return vnodes
        }
    }
</script>
