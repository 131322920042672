const keys = {
    userName : "user_name",
    TokenKey: "Admin-Token"
}

export function setToken(token) {
    return window.localStorage.setItem(keys.TokenKey, token);
}

export function getToken() {
    return window.localStorage.getItem(keys.TokenKey);
}

export function removerToken() {
    return window.localStorage.removeItem(keys.TokenKey);
}

export function set(key, val) {
    return window.localStorage.setItem(key, val);
}

export function get(key) {
    return window.localStorage.getItem(key);
}

export function remover(key) {
    return window.localStorage.removeItem(key);
}

export default keys
