import Vue from 'vue'
import store from "../store"

/** 判断是否拥有菜单权限 */
export const haveRole = (role) => {
    if(store.getters.isAdmin){
        return true;
    }
    return store.getters.roles.indexOf(role) > -1;
}


export default function(Vue) {
    //添加全局API
    Vue.prototype.$app = {
        haveRole
    }
}






