import request from '@/utils/request'
import {setToken, removerToken, getToken} from "@/utils/session";

const urls = {
    login: '/auth/login',
    getMenu: "/system/menu",
    getUserInfo: "/auth/user/getInfo",
    getUserWarehouse: 'system/user/getLoginUserWarehouse'
}

const state = {
    nickName: '',
    userInfo: undefined,
    roles: [],
    warehouse: []
}

const actions = {
    getUserWarehouse({commit}) {
        return new Promise((resolve, reject) => {
            request({
                url: urls.getUserWarehouse,
                method: 'GET'
            }).then(({data}) => {
                commit('USER_WAREHOUSE', data)
                resolve(data);
            }).catch(() => {reject()})
        })
    },
    login: ({commit}, params) => {
        return new Promise((resolve, reject) => {
            request({
                url: urls.login,
                data: params,
                method: 'POST'
            }).then(({data}) => {
                if(data) {
                    setToken(data.token)
                    resolve();
                }else {
                    reject("登录返回空");
                }
            }).catch(() => {reject()})
        })
    },
    logout: ({commit}) => {
        commit('LOGOUT');
    },
    getInfo: ({commit}) => {
        //获取用户信息
        return new Promise((resolve, reject) => {
            request({
                url: urls.getUserInfo,
                method: "GET"
            }).then(({data}) => {
                commit('SET_USERNAME', data.nickName)
                commit('SET_USER_INFO', data)
                commit('SET_USER_ROLES', data.roles)
                resolve(data);
            }).catch(() => {reject()})
        })
    },
    getMenu: ({commit}) => {
        //获取菜单列表
        return new Promise((resolve, reject) => {
            request({
                url: urls.getMenu,
                method: "GET"
            }).then(({data}) => {
                resolve(data);
            }).catch(() => {reject()})
        })
    }
}


const mutations = {
    'SET_USERNAME': (state, nickName) => {
        state.nickName = nickName;
    },
    'SET_USER_INFO': (state, userInfo) => {
        state.userInfo = userInfo;
    },
    'SET_USER_ROLES': (state, roles) => {
        state.roles = roles;
    },
    'LOGOUT': (state) => {
        state.roles = [];
        state.nickName = undefined
        state.userInfo = undefined
    },
    'USER_WAREHOUSE': (state, data) => {
        state.warehouse = data
    }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}
